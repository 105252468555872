import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Xampinyó blanc, blanc brut, rosat o terrós, amb un capell al principi esfèric, que després s’estén tornant convex i finalment pla de 4 a 12 cm de diàmetre. La superfície és llisa al principi, però pot presentar algunes esquames amb el temps. Les làmines estan lliures, atapeïdes, rosades al principi per passar a color xocolata, pel color de les espores. El peu és curt, cilíndric i gruixat de 3 a 5 x 1 a 2 cm, de color blanc i un poc grogós a la base i provist d’un anell membranós blanc que es perd fàcilment. Les espores són de color terrós porpra, ovoïdals, de 7-8 x 4-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      